@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700");
@import url("./assets/scss/icons/themify-icons/themify-icons.css");

.fast{
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode:both;
    animation-fill-mode:both
}

.pointer {
    cursor: pointer;
}

.ngx-pagination {
   font-size: 0.8em;
}

.mt-5 {
  margin-top: 5px;
}

.mt-2 {
  margin-top: 2px;
}

.ml-2 {
  margin-left: 2px;
}

.ml-3 {
  margin-left: 3px;
}

.ml-10 {
    margin-left: 10px;
}

.ml-15 {
    margin-left: 15px;
}

.ml-20 {
    margin-left: 20px;
}

.ml-50 {
    margin-left: 50px;
}

.mr-50 {
    margin-right: 50px;
}

.mb-50 {
    margin-bottom: 50px;
}

.pb-50 {
    padding-bottom: 50px;
}

.mr-100 {
    margin-right: 100px;
}

.mr-10 {
    margin-right: 10px;
}

.w50 {
  width: 50px;
}

.w80 {
  width: 80px;
}

.w100 {
  width: 100px;
}

.w150 {
  width: 150px;
}

.w200 {
  width: 200px;
}

.w300 {
  width: 300px;
}

/*
 * Styling boxes for club/championship/events
 */
.zround-box {
    border-radius: 12px; 
    background-color:#dddddd;
    border: 1px solid darkgray;
    padding: 10px;
}

.data h3 {
    font-size: 1.2em;
    color: #001058;
}

.data h4 {
    font-style: italic;
    font-size: 1em;
}

.data a {
    font-size: 0.8em;
}

.result-qualify {
    font-weight: bold;
    background-color: #DDFFDD;
}

.best-lap {
    color: #d200ff;
    font-style: italic;
}

.consistencia {
    margin-left: 5px;
    font-style: italic;
    font-size: smaller;
    color:#797979;
}

.user-avatar {
    width: 40px;
}

.avatar {
    width: auto;
    max-height: 20px;
    margin-right: 15px;
}

.class {
    font-style: italic;
    font-size: 0.8em;
    background-color: rgb(131, 189, 255);
    border-radius: 10px;
    border: 1px solid rgb(108, 157, 212);
    padding: 5px;
    color: white;
    margin: auto;
    margin-right: 15px;
}

.btn-championship {
    font-size: 0.8em;
    padding: 5px;
}

/* Checkboxes
   ========================================================================== */
/* CUSTOM CSS CHECKBOXES */
form p {
    margin-bottom: 10px;
    text-align: left; }
  
  form p:last-child {
    margin-bottom: 0; }
  
  /* Remove default checkbox */
  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
    opacity: 0; }
  
  [type="checkbox"] {
    /* checkbox aspect */ }
  
  [type="checkbox"] + label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    font-size: 1rem;
    -webkit-user-select: none;
    /* webkit (safari, chrome) browsers */
    -moz-user-select: none;
    /* mozilla browsers */
    -khtml-user-select: none;
    /* webkit (konqueror) browsers */
    -ms-user-select: none;
    /* IE10+ */ }
  
  [type="checkbox"] + label:before,
  [type="checkbox"]:not(.filled-in) + label:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    z-index: 0;
    border: 1px solid #b1b8bb;
    border-radius: 1px;
    margin-top: 2px;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s; }
  
  [type="checkbox"]:not(.filled-in) + label:after {
    border: 0;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0); }
  
  [type="checkbox"]:not(:checked):disabled + label:before {
    border: none;
    background-color: rgba(0, 0, 0, 0.26); }
  
  [type="checkbox"].tabbed:focus + label:after {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    border: 0;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.1); }
  
  [type="checkbox"]:checked + label:before {
    top: -4px;
    left: -5px;
    width: 12px;
    height: 22px;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid #26a69a;
    border-bottom: 2px solid #26a69a;
    -webkit-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    transform: rotate(40deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%; }
  
  [type="checkbox"]:checked:disabled + label:before {
    border-right: 2px solid rgba(0, 0, 0, 0.26);
    border-bottom: 2px solid rgba(0, 0, 0, 0.26); }
  
  /* Indeterminate checkbox */
  [type="checkbox"]:indeterminate + label:before {
    top: -11px;
    left: -12px;
    width: 10px;
    height: 22px;
    border-top: none;
    border-left: none;
    border-right: 2px solid #26a69a;
    border-bottom: none;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%; }
  
  [type="checkbox"]:indeterminate:disabled + label:before {
    border-right: 2px solid rgba(0, 0, 0, 0.26);
    background-color: transparent; }
  
  [type="checkbox"].filled-in + label:after {
    border-radius: 2px; }
  
  [type="checkbox"].filled-in + label:before,
  [type="checkbox"].filled-in + label:after {
    content: '';
    left: 0;
    position: absolute;
    /* .1s delay is for check animation */
    -webkit-transition: border .25s, background-color .25s, width .20s .1s, height .20s .1s, top .20s .1s, left .20s .1s;
    -o-transition: border .25s, background-color .25s, width .20s .1s, height .20s .1s, top .20s .1s, left .20s .1s;
    transition: border .25s, background-color .25s, width .20s .1s, height .20s .1s, top .20s .1s, left .20s .1s;
    z-index: 1; }
  
  [type="checkbox"].filled-in:not(:checked) + label:before {
    width: 0;
    height: 0;
    border: 3px solid transparent;
    left: 6px;
    top: 10px;
    -webkit-transform: rotateZ(37deg);
    -ms-transform: rotate(37deg);
    transform: rotateZ(37deg);
    -webkit-transform-origin: 20% 40%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%; }
  
  [type="checkbox"].filled-in:not(:checked) + label:after {
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 1px solid #b1b8bb;
    top: 0px;
    z-index: 0; }
  
  [type="checkbox"].filled-in:checked + label:before {
    top: 0;
    left: 1px;
    width: 8px;
    height: 13px;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    -webkit-transform: rotateZ(37deg);
    -ms-transform: rotate(37deg);
    transform: rotateZ(37deg);
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%; }
  
  [type="checkbox"].filled-in:checked + label:after {
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #26a69a;
    background-color: #26a69a;
    z-index: 0; }
  
  [type="checkbox"].filled-in.tabbed:focus + label:after {
    border-radius: 2px;
    border-color: #5a5a5a;
    background-color: rgba(0, 0, 0, 0.1); }
  
  [type="checkbox"].filled-in.tabbed:checked:focus + label:after {
    border-radius: 2px;
    background-color: #26a69a;
    border-color: #26a69a; }
  
  [type="checkbox"].filled-in:disabled:not(:checked) + label:before {
    background-color: transparent;
    border: 2px solid transparent; }
  
  [type="checkbox"].filled-in:disabled:not(:checked) + label:after {
    border-color: transparent;
    background-color: #BDBDBD; }
  
  [type="checkbox"].filled-in:disabled:checked + label:before {
    background-color: transparent; }
  
  [type="checkbox"].filled-in:disabled:checked + label:after {
    background-color: #BDBDBD;
    border-color: #BDBDBD; }
  